.menu_toggle {
  display: none;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  overflow: hidden;
  margin-left: 20px;
}
.burger_container {
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  position: relative;
}
.burger_element {
  width: 100%;
  height: 3px;
  background: var(--primary);
  border-radius: 3px;
  position: absolute;
}

.burger_element:nth-child(1) {
  transform: translateY(-7px);
}
.burger_element:nth-child(3) {
  transform: translateY(7px);
}

@keyframes rotate135deg {
  0% {
    transform: translateY(-7px);
    transform: rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(-45deg);
  }
}
@keyframes translateX {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(120%);
  }
}
@keyframes rotate45deg {
  0% {
    transform: translateY(7px);
    transform: rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(45deg);
  }
}

.menu_toggle.menu_anim .burger_element:nth-child(1) {
  animation: rotate135deg 0.5s forwards ease-in-out;
}
.menu_toggle.menu_anim .burger_element:nth-child(2) {
  animation: translateX 0.5s forwards ease-in-out;
}
.menu_toggle.menu_anim .burger_element:nth-child(3) {
  animation: rotate45deg 0.5s forwards ease-in-out;
}

@media all and (max-width: 767px) {
  .menu_toggle {
    display: flex;
  }
}
@media all and (max-width: 370px) {
  .menu_toggle {
    transform: scale(0.7);
  }
}
